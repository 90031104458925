

export default function Loadingbox(props) {
    return (
        <div className="loading">
            <i className="fa fa-spinner fa-spin text-secondary"></i>
            {props.onlyLoader? '': <p> {props.children ? props.children : 'Loading'}...</p>}
            
        </div>
    )
}
