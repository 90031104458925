export const COMPANY_REGISTER_REQUEST = 'COMPANY_REGISTER_REQUEST';
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS';
export const COMPANY_REGISTER_FAIL = 'COMPANY_REGISTER_FAIL';

export const COMPANY_DETAILS_REQUEST = 'COMPANY_DETAILS_REQUEST';
export const COMPANY_DETAILS_SUCCESS = 'COMPANY_DETAILS_SUCCESS';
export const COMPANY_DETAILS_FAIL = 'COMPANY_DETAILS_FAIL';

export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_FAIL = 'COMPANY_LIST_FAIL';