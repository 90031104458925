import React from 'react'
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="navbar navbar--fixed navbar--fixed-top navbar--page">
            
                <Link to='/dashboard' className="m-auto">
                    MY <span style={{ color: 'red' }}>ACCOUNTS</span>
                </Link>
            
        </div>
    )
}

export default Header;
