import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'
import { pinIn, signout } from '../../actions/userActions';
import Loadingbox from '../LoadingBox';
import MessageBox from '../MessageBox';

function PinCodeModal(props) {
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo, loading, pinCodeLogin } = userSignin;
    const [closeBtn, setCloseBtn] = useState(false);

    const [pinCode, setPinCode] = useState('');
    const [confirmPinCode, setConfirmPinCode] = useState('');
    const [matchFlag, setmatchFlag] = useState(false);

    const dispatch = useDispatch();
    const submitHandler = () => {
        if (matchFlag) {
                // console.log("pinin  ",userInfo._id, pinCode)

            dispatch(pinIn(
                pinCode
            ));
        } else {
            alert('Please Enter Correct Pin Codes')
        }
    }
    useEffect(() => {
        if (userInfo && userInfo.pinCodeSetup) {
            setmatchFlag(true);
        } else {
            if (pinCode === confirmPinCode) {
                setmatchFlag(true);
            } else {
                setmatchFlag(false)
            }
        }
    }, [pinCode, confirmPinCode, userInfo])
    const history = useHistory();
    useEffect(() => {
        if (pinCodeLogin) {
            if (pinCodeLogin.pinCodeLogin) {
                history.push('/dashboard');
            }
        }
    }, [userInfo, history, pinCodeLogin])
    return (
        <div id="emailModal" className={`modal ${props.modalStatus ? 'show' : ''}`}>

            <div className="modal-content">
                <div className="modal-header">
                    <span onClick={props.closeModal} className="close">&times;</span>
                    {userInfo ? userInfo.pinCodeSetup ? <h4>Enter Your Pin Code</h4> :
                        <h4>Setup Pin Code</h4> : <></>}
                    </div>
                <div className="modal-body">
                {loading && <Loadingbox/>}
                  {pinCodeLogin && pinCodeLogin.error && <MessageBox variant="danger">{pinCodeLogin.error}</MessageBox>}
                    <>
                    {userInfo ? userInfo.pinCodeSetup ?
                        <div className="contactform" id="dvform">
                            <div className="form_row">
                                <label>Enter Pin Code: </label>
                                <input type="number" onChange={(e) => setPinCode(e.target.value)}
                                    placeholder="Enter Your Pin" className="form_input required" />
                                </div>
                            </div> :
                        <>
                            {pinCode !== '' && confirmPinCode !== '' && matchFlag ?
                                <h6 className="match">Pin Codes are matched !</h6> : <></>}
                            {pinCode !== '' && confirmPinCode !== '' ? matchFlag ? <></> :
                                <h6 className="not-match">Pin Codes not match !</h6> : <></>}
                            <div className="contactform" id="dvform">
                                <div className="form_row">
                                    <label>Enter Pin Code: </label>
                                    <input type="number" onChange={(e) => setPinCode(e.target.value)}
                                        placeholder="Enter Your Pin"
                                        className={`form_input required ${pinCode !== '' && confirmPinCode !== '' && matchFlag ? 'match' : ''} 
                                ${pinCode !== '' && confirmPinCode !== '' ? matchFlag ? '' : 'not-match' : ''}`} />
                                </div>
                                <div className="form_row">
                                    <label>Confirm Pin Code: </label>
                                    <input type="number" onChange={(e) => setConfirmPinCode(e.target.value)}
                                        placeholder="Confirm Your Pin"
                                        className={`form_input required ${pinCode !== '' && confirmPinCode !== '' && matchFlag ? 'match' : ''}
                                ${pinCode !== '' && confirmPinCode !== '' ? matchFlag ? '' : 'not-match' : ''}`} />
                                </div>
                                </div>
                            </> : <></>}
                        
                            <div className="btn-div">
                            <button onClick={() => submitHandler()} >
                                Sign In
                            </button>
                            <button onClick={() => dispatch(signout())}>
                                Sign Out
                            </button>
                                </div>
                            </>
                </div>
            </div>
        </div>
    )
}

export default PinCodeModal;
