import Axios from "axios";
import { serverAddress } from "../constants/dbConstants";
import { USER_PININ_FAIL, USER_PININ_REQUEST, USER_PININ_SUCCESS, USER_PINOUT, USER_SIGNIN_FAIL, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNOUT } from "../constants/userConstants"

export const signin = (email, password) => async (dispatch) => {
    dispatch({
        type: USER_SIGNIN_REQUEST,
        payload: {
            email,
            password
        }
    });
    try {
        const { data } = await Axios.post(`${serverAddress}/api/users/signin`, { email, password });
        dispatch({
            type: USER_SIGNIN_SUCCESS,
            payload: data,
        });
        localStorage.setItem('userInfo', JSON.stringify(data));
     } catch (err) {
        dispatch({
            type: USER_SIGNIN_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

export const pinIn = ( pinCode) => async (dispatch) => {
    const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
    const token = user.token;
    dispatch({
        type: USER_PININ_REQUEST,
        payload: {
            pinCode
        }
    });
    try {
        const { data } = await Axios.post(`${serverAddress}/api/users/pinin`, { pinCode },{
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
        localStorage.setItem('pinCodeLogin', JSON.stringify(data));
        dispatch({
            type: USER_PININ_SUCCESS,
            payload: data,
        });
        // localStorage.setItem('pinCodeLogin', JSON.stringify(data));
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        console.log(userInfo);
        userInfo = {
            ...userInfo,
            pinCodeSetup: true
        };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        
     } catch (err) {
        dispatch({
            type: USER_PININ_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

export const updatePassword = ( password) => async (dispatch) => {
    const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
    const token = user.token;
    try {
        const { data } = await Axios.post(`${serverAddress}/api/users/update-password`, { password },{
            headers: {
                'authorization': `Bearer ${token}`
            }
        });

     } catch (err) {
    }
};

export const updatePincode = ( pincode) => async (dispatch) => {
    const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
    const token = user.token;

    try {
        const { data } = await Axios.post(`${serverAddress}/api/users/update-pincode`, { pincode },{
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
        
     } catch (err) {

    }
};

export const signout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    dispatch({ type: USER_SIGNOUT });
};

export const pinout = () => (dispatch) => {
    localStorage.removeItem('pinCodeLogin');
    dispatch({ type: USER_PINOUT });
};